import React, { useEffect } from "react";
import Auxiliary from "../../utilities/Auxiliary";
import Loader from "./Loader";
import Alert from "./Alert";

const AppNotificationContainer = ({ loading, message, type }) => {
  return (
    <Auxiliary>
      {loading && <Loader />}
      {message && <Alert type={type} message={message} />}
    </Auxiliary>
  );
};

export default AppNotificationContainer;
