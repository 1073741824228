import React from "react";
import { Field } from "formik";
import { TextField } from "@mui/material";

function InputText(props) {
  const { label, name, placeholder, ...rest } = props;

  return (
    <Field name={name}>
      {({ field, form }) => (
        <TextField
          //placeholder={placeholder}
          id={name}
          name={name}
          label={label}
          error={form.errors[name] && form.touched[name] ? true : false}
          {...rest}
          {...field}
        />
      )}
    </Field>
  );
}

export default InputText;
