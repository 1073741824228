import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "@mui/material/Avatar";

const Dshboard = () => {
  const dispatch = useDispatch();
  const { fullname } = useSelector((state) => state.auth);

  console.log(fullname);
  return (
    <div className="relative flex w-full flex-col bg-white">
      <div className="relative grid overflow-hidden text-center text-white bg-red-500 place-items-center rounded-xl bg-clip-border shadow-gray-900/20">
        <div className="p-3 text-white">
          <img
            src="https://tecdn.b-cdn.net/img/new/avatars/2.webp"
            className="w-24 rounded-full"
            alt="Avatar"
          />
          {/* <Avatar sx={{ width: 96, height: 96 }}>RM</Avatar> */}
        </div>
        <h5 className="block font-sans text-lg antialiased font-semibold leading-snug tracking-normal text-white">
          {fullname}
        </h5>
        <span className="my-3 text-sm text-white">Sales Staff</span>
      </div>
      <div className="flex flex-wrap space-x-4 justify-center items-center overflow-auto">
        <div className="cursor-pointer group relative w-5/12 my-3 bg-white shadow-sm border border-slate-200 rounded-lg hover:shadow-lg transition-shadow duration-300">
          <div className="relative m-3 overflow-hidden text-white rounded-md">
            <img className="square-full" src="assets/images/office_works.png" />
          </div>
          <div className="p-3">
            <h6 className="text-slate-800 text-lg font-semibold text-center">
              Office Works
            </h6>
          </div>
        </div>
        <div className="cursor-pointer group relative w-5/12 my-3 bg-white shadow-sm border border-slate-200 rounded-lg w-5/12 hover:shadow-lg transition-shadow duration-300">
          <div className="relative m-3 overflow-hidden text-white rounded-md">
            <img
              className="square-full"
              src="assets/images/factory_works.png"
            />
          </div>
          <div className="p-3">
            <h6 className="text-slate-800 text-lg font-semibold text-center">
              Factory Works
            </h6>
          </div>
        </div>
        <div className="cursor-pointer group relative w-5/12 my-3 bg-white shadow-sm border border-slate-200 rounded-lg w-5/12 hover:shadow-lg transition-shadow duration-300">
          <div className="relative m-3 overflow-hidden text-white rounded-md">
            <img
              className="square-full"
              src="assets/images/distributor_visits.png"
            />
          </div>
          <div className="p-3">
            <h6 className="text-slate-800 text-lg font-semibold text-center">
              Distributor Visit
            </h6>
          </div>
        </div>
        <div className="cursor-pointer group relative w-5/12 my-3 bg-white shadow-sm border border-slate-200 rounded-lg w-5/12 hover:shadow-lg transition-shadow duration-300">
          <div className="relative m-3 overflow-hidden text-white rounded-md">
            <img
              className="square-full"
              src="assets/images/distributor_visits.png"
            />
          </div>
          <div className="p-3">
            <h6 className="text-slate-800 text-lg font-semibold text-center">
              Survey
            </h6>
          </div>
        </div>

        {/* <div className="m-3 w-5/12 bg-red-50 rounded-lg sahdow-lg">
          <img
            src="assets/images/timeline.png"
            className="square-full"
            alt="distributor_visits"
          />
        </div> */}
      </div>
    </div>
  );
};

export default Dshboard;
