import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "@redux-devtools/extension";
import { thunk } from "redux-thunk";

import monitorReducersEnhancer from "./enhancers/monitorReducer";
import loggerMiddleware from "./middleware/logger";
import createRootReducer from "./reducer";

export default function configureStore(preloadedState) {
  const middlewares = [loggerMiddleware, thunk];
  const middlewareEnhancer = applyMiddleware(...middlewares);

  const enhancers = [middlewareEnhancer, monitorReducersEnhancer];
  const composedEnhancers = composeWithDevTools(...enhancers);

  const store = createStore(
    createRootReducer,
    preloadedState,
    composedEnhancers
  );

  // if (process.env.NODE_ENV !== "production" && module.hot) {
  //   module.hot.accept("./reducer", () =>
  //     store.replaceReducer(createRootReducer)
  //   );
  // }

  return store;
}
