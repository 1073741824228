import { combineReducers } from "redux";
import Auth from "./slice/auth";
import Users from "./slice/users";
import Common from "./slice/common";

const createRootReducer = combineReducers({
  //router: connectRouter(history),
  auth: Auth,
  users: Users,
  common: Common,
});

export default createRootReducer;
