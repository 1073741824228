import * as React from "react";
import Alert from "@mui/material/Alert";

export default function ColorAlerts({ type, message }) {
  return (
    <Alert severity={type} color={type}>
      {message}
    </Alert>
  );
}
