import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { jwtDecode } from "jwt-decode";
import api from "../client";
const token = localStorage.getItem("token");

var isValidToken = false;
var username = "";
var fullname = "";
let permissions = [];

if (token) {
  var decodedToken = jwtDecode(token);
  console.log(decodedToken);
  if (decodedToken.exp * 1000 > Date.now()) {
    isValidToken = true;
    username =
      decodedToken[
        "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"
      ];
    fullname =
      decodedToken[
        "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname"
      ];
    permissions = decodedToken["permission"];
  } else localStorage.removeItem("token");
}
console.log("reloaded", username);

export const loginUser = createAsyncThunk(
  "auth/signIn",
  async (userData, thunkAPI) => {
    return api.post("auth/signIn", userData).then(function (response) {
      return response.data;
    });
  }
);
export const registerUser = createAsyncThunk(
  "User/register",
  async (user, thunkAPI) => {
    const url = "auth/Register";
    return api.post(url, user).then(function (response) {
      return response.data;
    });
  }
);
export const profile = createAsyncThunk(
  "Auth/Profile",
  async (thunkAPI) => {
    const url = "auth/me";
    return api.get(url).then(function (response) {
      return response.data;
    });
  },
  {
    condition: (thunkAPI, { getState, extra }) => {
      const { user } = getState().auth;
      if (user) return false;
    },
  }
);
export const logoutUser = createAsyncThunk(
  "auth/logout",
  async (userData, thunkAPI) => {
    localStorage.removeItem("token");
  }
);
const initialState = isValidToken
  ? {
      isLoading: false,
      isLoggedIn: true,
      username,
      fullname,
      permissions,
      error: null,
    }
  : {
      isLoading: false,
      isLoggedIn: false,
      username: null,
      fullname: null,
      user: null,
      message: null,
      permissions: [],
      error: null,
    };

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state, action) => {
        state.isLoading = true;
        state.isLoggedIn = false;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        console.log(action);
        state.isLoading = false;
        if (action.payload.succeeded) {
          state.isLoggedIn = true;
          var token = action.payload.data.access_token;
          localStorage.setItem("token", token);
          var decodedToken = jwtDecode(token);
          state.permissions = decodedToken["permission"];
        } else {
          state.isLoggedIn = false;
          state.error = action.payload.message;
        }
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isLoggedIn = false;
        state.error = action.error.message;
      })
      .addCase(registerUser.pending, (state, action) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        console.log(action);
        state.isLoading = false;
        if (action.payload.succeeded) {
        } else {
          state.error = action.payload.message;
        }
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(logoutUser.fulfilled, (state, action) => {
        state.isLoggedIn = false;
        state.message = "Logout successfully.";
      })
      .addCase(profile.fulfilled, (state, action) => {
        state.user = action.payload.data;
        state.fullname = action.payload.data.fullName;
      })
      .addCase(profile.rejected, (state, action) => {
        state.user = null;
      });
  },
});

export default slice.reducer;
