import React, { useEffect } from "react";
import { Checkbox, FormControlLabel, Link } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import AppControl from "../components/";
import { loginUser } from "../store/slice/auth";
import AppNotificationContainer from "../components/controls/AppNotificationContainer";

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoggedIn, isLoading, message, error } = useSelector(
    (state) => state.auth
  );
  useEffect(() => {
    if (isLoggedIn) navigate("/dashboard");
  }, [isLoggedIn]);

  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email format").required("Required"),
    password: Yup.string().required("Required"),
  });

  const onSubmit = (values) => {
    dispatch(loginUser(values));
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        return (
          <Form>
            <div className="flex h-screen justify-center items-center">
              <div className="flex flex-col md:flex-row md:max-w-xl rounded-lg bg-white shadow-lg">
                <img
                  className="w-full h-48 md:h-auto object-cover md:w-48 rounded-t-lg md:rounded-none md:rounded-l-lg"
                  src="assets/images/calendar.png"
                  alt=""
                />
                <div className="p-5 flex flex-col w-80 justify-start">
                  <h5 className="text-center text-gray-900 text-xl font-medium mb-2">
                    Log In
                  </h5>
                  <AppNotificationContainer
                    className="mb-3"
                    loading={isLoading}
                    message={error}
                    type="error"
                  />
                  <AppControl
                    control="text"
                    type="email"
                    label="Email"
                    name="email"
                    size="small"
                  />
                  <br />
                  <AppControl
                    control="password"
                    type="password"
                    label="Password"
                    name="password"
                    visible="true"
                    size="small"
                  />
                  <div className="flex justify-between items-center mb-2 text-sm">
                    <FormControlLabel
                      control={<Checkbox />}
                      label="Remember Me"
                    />
                    <Link href="#" underline="none">
                      {"Forgot Password"}
                    </Link>
                  </div>
                  <AppControl
                    control="button"
                    type="submit"
                    name="Log In"
                    size="full"
                    color="blue"
                    //disabled={!formik.isValid || isLoading}
                    //loading={isLoading}
                  />
                  <p className="text-gray-800 mt-6 text-center">
                    Not a member?{" "}
                    <Link href="#" underline="none">
                      Register
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default Login;
